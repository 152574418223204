






















































































































































































































































































































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { StyleCheck } from "@/mixins/style-check";
import { FileCheck } from "@/mixins/file-check";
import FilePicker from "@/components/Input/FilePicker.vue";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, FilePicker, DotsMenu },
})
export default class Withdrawals extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck,
  FileCheck
) {
  search = "";
  dialog = false;
  loader = false;
  loading = false;
  loadingAWV = false;
  loadingDT = false;
  options = {};
  withdrawalInfo: any = null;
  withdrawalDetail = false;
  voucherToWithdrawal = false;
  exceptionalDialog = false;
  isLoading = false;
  userSearch = "";
  exceptionalRequest: any = {
    amount: 0,
    description: "",
    user_id: "",
    platform: "",
    platform_name: "",
    accountInfo: "",
    user: null,
    file: null,
  };

  amount: number | string = 0;
  platform: any = null;
  platform_name = "N/A";
  accountInfo = this.$tc("Views.v-42");
  file: any = null;

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private userChange(user: any) {
    this.exceptionalRequest.accountInfo = "";
    this.exceptionalRequest.platform_name = "";
    this.exceptionalRequest.platform = "";
    this.exceptionalRequest.amount = 0;
    this.exceptionalRequest.description = "";
    this.exceptionalRequest.file = null;
    if (user) this.exceptionalRequest.user_id = user.id;
  }

  private setAccountInfoForER(account: any) {
    this.exceptionalRequest.accountInfo = account.value;
    this.exceptionalRequest.platform_name = account.platform.name;
  }

  private customFilter(item, queryText) {
    let text =
      item.id.toLowerCase() +
      item.email.toLowerCase() +
      item.phone.toLowerCase() +
      item.alias.toLowerCase() +
      item.identification.toLowerCase();
    if (item.first_name) text += item.first_name.toLowerCase();
    if (item.second_name) text += item.second_name.toLowerCase();
    if (item.last_name) text += item.last_name.toLowerCase();
    if (item.second_last_name) text += item.second_last_name.toLowerCase();

    const searchText = queryText.toLowerCase().replaceAll(/\s/g, "");

    return text.indexOf(searchText) > -1;
  }

  @Watch("userSearch")
  private userSearchHandler(value: string) {
    if (value == null) return;

    if (this.isLoading) return;

    this.isLoading = true;

    this.$store
      .dispatch("withdrawal/getExceptionalUsers", value)
      .finally(() => {
        this.isLoading = false;
      });
  }

  private get exceptionalUsers() {
    return this.$store.getters["withdrawal/getExceptionalUsers"];
  }

  private get voucherProgress() {
    return this.$store.getters["withdrawal/getVoucherProgress"];
  }

  private async addWithdrawalVoucherFER() {
    this.loadingAWV = true;
    const voucher = new FormData();
    voucher.append("VOUCHER", this.exceptionalRequest.file);
    await this.$store
      .dispatch("withdrawal/uploadVoucher", voucher)
      .then(async (response) => {
        await this.$store
          .dispatch("withdrawal/exceptionalWithdrawalRequest", {
            amount: parseFloat(this.exceptionalRequest.amount as string),
            description: this.exceptionalRequest.description,
            user_id: this.exceptionalRequest.user_id,
            voucher_file: response.data.id,
            platform: this.exceptionalRequest.platform.fk_platform,
          })
          .then(async () => {
            await this.getWithdrawals(
              this.pagination.page,
              this.pagination.itemsPerPage
            );

            const success: Notification = {
              message: this.$tc("Views.ew-11"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: false,
            };

            this.$store.dispatch("notifications/showNotification", success);
            this.closeExceptionalDialog();
          })
          .catch(() => {
            this.loadingAWV = false;
            const Error: Notification = {
              message: this.$tc("Views.ew-e1"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ew-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loadingAWV = false;
      });
  }

  private async addWithdrawalVoucher() {
    this.loadingAWV = true;
    const voucher = new FormData();
    voucher.append("VOUCHER", this.file);
    await this.$store
      .dispatch("withdrawal/uploadVoucher", voucher)
      .then((response) => {
        this.$store
          .dispatch("withdrawal/addWithdrawalVoucher", {
            withdrawal_id: this.withdrawalInfo.id,
            amount: parseFloat(this.amount as string),
            voucher_file: response.data.id,
            platform: this.platform.fk_platform,
          })
          .then(async () => {
            await this.getWithdrawals(
              this.pagination.page,
              this.pagination.itemsPerPage
            );

            const success: Notification = {
              message: this.$tc("Views.v-43"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: false,
            };

            this.loadingAWV = false;
            this.$store.dispatch("notifications/showNotification", success);
            this.closeVoucher();
          })
          .catch(() => {
            this.loadingAWV = false;
            const Error: Notification = {
              message: this.$tc("Views.v-e1"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.v-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  get exceededAmount() {
    if (!this.withdrawalInfo) {
      return false;
    }
    return (
      parseFloat(this.amount as string) >
      parseFloat(this.withdrawalInfo.amount) -
        parseFloat(this.withdrawalInfo.total_paid)
    );
  }

  private get checkVoucher() {
    if (this.withdrawalInfo !== null && this.platform !== null) {
      if (
        parseFloat(this.amount as string) <=
          parseFloat(this.withdrawalInfo.amount) -
            parseFloat(this.withdrawalInfo.total_paid) &&
        parseFloat(this.amount as string) > 0 &&
        this.platform.fk_platform > 0 &&
        this.file !== null
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private get checkVoucherFER() {
    if (
      this.exceptionalRequest.user_id !== "" &&
      this.exceptionalRequest.user_id !== null &&
      this.exceptionalRequest.amount !== "" &&
      this.exceptionalRequest.amount !== null &&
      this.exceptionalRequest.description !== "" &&
      this.exceptionalRequest.description !== null &&
      this.exceptionalRequest.platform !== "" &&
      this.exceptionalRequest.platform !== null &&
      this.exceptionalRequest.platform_name !== "" &&
      this.exceptionalRequest.platform_name !== null &&
      this.exceptionalRequest.accountInfo !== "" &&
      this.exceptionalRequest.accountInfo !== null &&
      this.exceptionalRequest.user !== "" &&
      this.exceptionalRequest.user !== null &&
      this.exceptionalRequest.file !== "" &&
      this.exceptionalRequest.file !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  private closeExceptionalDialog() {
    this.exceptionalDialog = false;
    this.exceptionalRequest.amount = 0;
    this.exceptionalRequest.description = "";
    this.exceptionalRequest.user_id = "";
    this.exceptionalRequest.platform = "";
    this.exceptionalRequest.platform_name = "";
    this.exceptionalRequest.accountInfo = "";
    this.exceptionalRequest.user = null;
    this.exceptionalRequest.file = null;
    this.$store.dispatch("withdrawal/resetVoucherProgress");
  }

  private closeVoucher() {
    this.voucherToWithdrawal = false;
    this.withdrawalInfo = null;
    this.amount = 0;
    this.platform = 0;
    this.platform_name = "N/A";
    this.accountInfo = this.$tc("Views.v-42");
    this.file = null;
    this.$store.dispatch("withdrawal/resetVoucherProgress");
  }

  private setAccountInfo(account: any) {
    this.accountInfo = account.value;
    this.platform_name = account.platform.name;
  }

  private closeDetail() {
    this.withdrawalInfo = null;
    this.withdrawalDetail = false;
  }

  private openWithdrawalDetail(withdrawal: any) {
    this.withdrawalInfo = withdrawal;
    this.withdrawalDetail = true;
  }

  private openVoucherToWithdrawal(withdrawal: any) {
    this.withdrawalInfo = withdrawal;
    this.voucherToWithdrawal = true;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getWithdrawals(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage
    );
    this.loadingDT = false;
  }

  private async getWithdrawals(
    page: number,
    size: number,
    withdrawal_id = ""
  ): Promise<void> {
    await this.$store
      .dispatch("withdrawal/getWithdrawals", {
        page,
        size,
        withdrawal_id,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.v-e3"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getWithdrawals(
      this.pagination.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.s-h1"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h3"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h5"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.ew-15"),
        value: "description",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get withdrawals(): any[] {
    let response = this.$store.getters["withdrawal/getWithdrawals"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.withdrawals) {
      return response?.withdrawals;
    } else {
      return [];
    }
  }

  //Search by ID
  private async searchWithdrawalByID(clear: boolean) {
    this.loadingDT = true;
    if (this.search == null || clear === true) {
      this.search = "";
    }

    await this.getWithdrawals(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }
}
